<template>
  <section id="events" class="gap-4 lg:gap-6">
    <h2
      v-if="items.length"
      class="max-w-[400px] text-neutral text-xl leading-7 font-bold uppercase text-center mx-auto mb-4"
    >
      {{ $t('home.events.title') }}
    </h2>
    <DefineEventCard v-slot="item">
      <div
        class="relative flex flex-col items-end gap-2 md:gap-4 w-[435px] max-w-[calc(85vw-2rem)] aspect-[9/10] md:aspect-square mb-4 pr-6 py-6 rounded-lg overflow-hidden shadow-lg cursor-pointer"
      >
        <img
          v-if="item?.image"
          :src="item.image"
          :srcset="item.imageSet"
          loading="lazy"
          :alt="item.imageAlt"
          class="absolute z-[-1] left-0 top-0 w-full h-full object-cover object-center"
          width="435"
          height="435"
        />
        <div
          class="px-2 md:mb-1 text-lg md:text-xl leading-5 md:leading-8 text-white text-right font-bold drop-shadow-lg"
        >
          {{ item.title }}
        </div>
        <div
          v-if="item.speaker"
          class="px-2 leading-5 text-sm md:text-base text-white text-right font-bold drop-shadow-lg"
        >
          {{ $t('common.by', [item.speaker]) }}
          <br v-if="item.speaker && item.speakerPosition" />
          {{ item.speakerPosition }}
        </div>
        <div
          v-else-if="item.description"
          class="px-2 leading-5 text-sm md:text-base text-white text-right font-bold drop-shadow-lg"
        >
          {{ item.description }}
        </div>
        <div
          class="px-2 mt-auto leading-5 md:leading-6 text-base md:text-lg text-white text-right drop-shadow-lg"
        >
          {{ item.date }}, {{ item.time }}<br />{{ item.place }}
        </div>
        <AppButton
          class="rounded text-sm md:text-base min-h-[36px] md:min-h-[48px] w-[260px] max-w-[75%] py-1 shadow"
        >
          {{ item.cta || $t('home.events.registerButton') }}
        </AppButton>
      </div>
    </DefineEventCard>
    <Transition name="modal">
      <AppModal
        v-if="showForm"
        class="modal-container fix-page"
        window-class="relative max-w-[calc(100vw-16px)] max-h-[calc(100svh-32px)] min-[412px]:max-w-[480px] w-full bg-white rounded-md md:rounded-lg shadow-lg py-4 px-8 overflow-y-auto"
        @click="closeEventForm"
      >
        <AppButton
          class="icon-button variant-invisible ml-auto h-8 w-8 !absolute right-2 top-2"
          :icon="mdiClose"
          :aria-label="$t('common.close')"
          :tabindex="activeForm ? 0 : -1"
          @click="closeEventForm"
        />
        <div class="font-bold text-lg md:text-xl mb-4">
          {{ $t('home.events.formMessage') }}
        </div>
        <HubspotForm
          class="w-full"
          :element-id="`event-popup-form-${activeForm.formId}`"
          :form-id="activeForm.formId"
          :portal-id="activeForm.formPortalId"
          :region="activeForm.formRegion"
        />
      </AppModal>
    </Transition>
    <Splide
      v-if="items.length"
      :options="{
        focus: false,
        perMove: 3,
        drag: 'free',
        autoWidth: true,
        gap: '16px',
        pagination: false,
        trimSpace: 'move',
        omitEnd: true,
        i18n: {
          slide: ''
        },
        breakpoints: {
          640: {
            arrows: false
          }
        },
        slideFocus: true,
        focusableNodes: '.splide__slide, button'
      }"
      class="splide-center mb-6"
    >
      <SplideSlide
        v-for="(item, index) in items"
        :key="index"
        @click="() => openEventForm(item)"
        @enter="() => openEventForm(item)"
      >
        <EventCard v-bind="item"></EventCard>
      </SplideSlide>
    </Splide>
  </section>
</template>

<script setup lang="ts">
import { mdiClose } from '@mdi/js';
import { Splide, SplideSlide } from '@splidejs/vue-splide';
import '@splidejs/vue-splide/css/core';
import { createReusableTemplate } from '@vueuse/core';

import { GET_WP_EVENTS } from '@/utils/queries';
import { fixImageUrl } from '@/helpers/fixes';

const AppModal = defineAsyncComponent(
  () => import('@/async-components/App/Modal.vue')
);
const HubspotForm = defineAsyncComponent(
  () => import('@/async-components/HubspotForm.vue')
);

interface Event {
  title: string;
  subTitle?: string;
  to: string;
  image: string;
  imageSet: string;
  imageAlt: string;
  speaker: string;
  speakerPosition?: string;
  description?: string;
  date: string;
  time: string;
  place: string;
  reservationLink: string;
  cta?: string;
  formId: string;
  formPortalId: string;
  formRegion: string;
}

interface EventFromResponse {
  id: string;
  title: string;
  link: string;
  fields: {
    subTitle: string;
    speaker?: string;
    speakerPosition?: string;
    description?: string;
    address: string;
    datetime: string;
    linkTo: string;
    cta?: string;
    formId: string;
    portalId: string;
    region: string;
  };
  featuredImage?: {
    node?: {
      sourceUrl: string;
      altText: string;
    };
  };
}

const [DefineEventCard, EventCard] = createReusableTemplate<Event>();

const {
  public: { cloudflareImageProcessing: cfUrl }
} = useRuntimeConfig();

const { language } = useLanguage();

const client = useWpClient();

const activeForm = ref<{
  formId: string;
  formPortalId: string;
  formRegion: string;
} | null>(null);

function openEventForm(item) {
  if (item?.formId) {
    activeForm.value = {
      formId: item.formId,
      formPortalId: item.formPortalId,
      formRegion: item.formRegion
    };
  }
}

const showForm = computed(() => {
  return !!activeForm.value;
});

function closeEventForm(e) {
  activeForm.value = null;
}

function stripHTML(html: string) {
  if (!html || typeof html !== 'string') {
    return '';
  }
  return html
    .replace(/<[^>]*>?/gm, '')
    .replace(/\s+/g, ' ')
    .trim();
}

async function fetch() {
  const gqlResponse = await client.query<{
    events: {
      nodes: EventFromResponse[];
    };
  }>({
    query: GET_WP_EVENTS,
    variables: {
      language: language.value
    }
  });

  let nodes = [...gqlResponse.data.events.nodes].map(v => ({
    ...v,
    datetime: new Date(
      (v.fields.datetime || '').split(' ')[0].split('/').reverse().join('-') +
        ' ' +
        (v.fields.datetime || '').split(' ')[1]
    )
  }));

  nodes = nodes.filter(node => {
    const today = new Date(new Date().setHours(0, 0, 0, 0));
    return node.datetime >= today;
  });

  nodes.sort((a, b) => {
    return a.datetime.getTime() - b.datetime.getTime();
  });

  return nodes.slice(0, 15).map(node => {
    const dateParts = node.fields.datetime.split(' ')[0].split('/');
    const date = new Date(
      +dateParts[2],
      +dateParts[1] - 1,
      +dateParts[0]
    ).toLocaleDateString(language.value, {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    });
    return {
      title: node.title,
      subTitle: node.fields.subTitle,
      to: node.fields.linkTo,
      image: node.featuredImage?.node?.sourceUrl,
      imageAlt: node.featuredImage?.node?.altText,
      speaker: node.fields.speaker,
      speakerPosition: node.fields.speakerPosition,
      description: stripHTML(node.fields.description),
      date,
      datetime: node.datetime,
      time: node.fields.datetime.split(' ').slice(1).join(' '),
      place: node.fields.address,
      reservationLink: node.fields.linkTo,
      cta: node.fields.cta,
      formId: node.fields.formId,
      formPortalId: node.fields.portalId,
      formRegion: node.fields.region
    };
  });
}

const { data: asyncItems, error } = await useAsyncData<Event[]>(async () => {
  if (process.client) {
    return [];
  }
  return await fetch();
});

const clientItems = ref<Event[]>([]);

onMounted(() => {
  if (!asyncItems.value?.length) {
    fetch().then(response => (clientItems.value = response));
  }
});

const items = computed(() => {
  const items = asyncItems.value?.length ? asyncItems.value : clientItems.value;

  return items
    .filter(node => {
      const today = new Date(new Date().setHours(0, 0, 0, 0));
      return node.datetime >= today;
    })
    .map(item => {
      const fixedImage = item.image ? fixImageUrl(item.image) : '';
      const image = fixedImage
        ? `${cfUrl}/image?w=435&h=435&f=webp&fit=crop&image=${fixedImage}`
        : '';
      const imageSet = fixedImage
        ? `${image} 1x, ${cfUrl}/image?w=870&h=870&f=webp&fit=crop&image=${fixedImage} 2x`
        : '';
      return {
        ...item,
        image,
        imageSet
      };
    });
});

if (error.value) {
  console.error(error.value);
}
</script>

<style lang="scss">
@import '~/assets/css/splide-theme.scss';
</style>
